import { Layout } from '@/bloks/Global/Layout';
import { DefaultProps, buildGetStaticProps } from '@/server/slug';
import { isSlugClean } from '@/utils/string';
import { ClientProviders } from 'client/ClientProviders';
import React from 'react';
import { GetStaticPaths } from 'next';

const Page: React.FC<DefaultProps> = ({ ...props }) => {
	return (
		<ClientProviders providerParams={props}>
			<Layout {...props} />
		</ClientProviders>
	);
};

export default Page;

export const getStaticProps = buildGetStaticProps<DefaultProps>({
	initialize(params) {
		if (!params.slug) {
			const pageSlug = '';
			const storySlug = 'home';
			return { build: { pageSlug, storySlug } };
		}

		const pageSlug = (params.slug as string[]).join('/');

		if (!isSlugClean(pageSlug)) {
			return { notFound: true };
		}

		const storySlug = pageSlug;

		return { build: { pageSlug, storySlug } };
	},
	async build(helper) {
		await helper.fetchProps({});
		await helper.fetchModulesData();
		await helper.fetchBreadcrumbs();
		helper.fetchProductData();
		helper.handleServerPageConfig();
	},
});

export const getStaticPaths: GetStaticPaths = async () => {
	return { paths: [], fallback: 'blocking' };
};
